import React from "react";
import HeroSection from "../components/nyheter/HeroSection";
import Contents from "../components/nyheter/Contents";
import FooterSection from "../components/FooterSection";
import ScrollToTop from "../components/scrollToTop";

const Nyheter = () => {
  return (
    <div className="flex w-full flex-col justify-center items-center">
      <ScrollToTop />
      <HeroSection />
      <Contents />
      <FooterSection />
    </div>
  );
};

export default Nyheter;
