import React, { useEffect, useState } from "react";
import automation from "../../assets/images/automation.png";
import image from "../../assets/images/image.png";
import batteri from "../../assets/images/batteri.png";
import after from "../../assets/icons/after.svg";
import { useNavigate } from "react-router-dom";

const EnergieffektivaSection = () => {
  const navigate = useNavigate();
  const [slide, setSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setSlide((prevSlide) => (prevSlide + 1) % 2); // Loop from 0 to 2
    }, 5000);

    return () => clearInterval(interval); // Clear interval on component unmount
  }, []);

  return (
    <div className="h-[790px] md:h-[400px] lg:h-[450px] 2xl:h-[550px] relative">
      <div
        className={`py-8 px-5 md:py-0 md:px-0    lg:mt-16 2xl:mt-28  flex flex-col lg:gap-16 2xl:gap-0 lg:items-end  lg:flex-row-reverse lg:justify-around w-full  2xl:items-center  absolute top-0 left-0 opacity-0 duration-700 ${
          slide === 0 ? "opacity-100" : "opacity-0"
        } `}
      >
        <img
          src={automation}
          alt="automation"
          width={520}
          height={300}
          className="w-[279px] h-[144px] object-cover md:hidden lg:block lg:w-[469px] lg:h-[269px] lg:mr-10 2xl:mx-72 "
        />
        <div className="font-400 md:ml-16 lg:ml-28 2xl:ml-48 max-w-[625px] md:hidden lg:block  ">
          <h2 className="text-[32px] md:text-[48px] mt-5 leading-[55px]">
            Energieffektiva lösningar inom elinstallation
          </h2>
          <h3 className="text-[18px] mt-2 max-w-[319px]  md:max-w-full md:mt-5">
            Eftersom energikostnaden är löpande blir energioptimering en
            investering både för dig och för miljön. Genom att underhålla,
            uppgradera och digitalisera fastigheter kan vi minska din
            energianvändning. Låt oss på Egestam & Mattsson ge förslag på
            lösningar och göra konkreta insatser.   
          </h3>
          <button
            className="bg-[#89BF50] text-[16px] text-white max-w-[330px] md:max-w-[500px] rounded-[48px] py-2 px-14 md:px-16 md:mt-5  relative z-[1] "
            onClick={() => navigate("/nyheter/Nyheter")}
          >
            Så här jobbar vi med energioptimering{" "}
            <img
              src={after}
              alt="after"
              width={18}
              height={18}
              className="absolute bottom-2 right-14 md:right-5"
            />
          </button>
        </div>
        <div
          className="hidden md:block lg:hidden bg-blue-300 w-full bg-no-repeat bg-cover h-[433px] text-white relative"
          style={{
            backgroundImage: `url(${automation})`,
          }}
        >
          <div className="w-full h-full bg-[#00354E] absolute opacity-60 z-0"></div>
          <div className="font-400 md:ml-10 lg:ml-28 2xl:ml-48 max-w-[700px] h-full flex flex-col justify-center absolute">
            <h2 className="text-[32px] md:text-[48px] mt-5 leading-[50px]">
              Energieffektiva lösningar inom elinstallation
            </h2>
            <h3 className="text-[18px] mt-2 ">
              Eftersom energikostnaden är löpande blir energioptimering en
              investering både för dig och för miljön. Genom att underhålla,
              uppgradera och digitalisera fastigheter kan vi minska din
              energianvändning. Låt oss på Egestam & Mattsson ge förslag på
              lösningar och göra konkreta insatser.   
            </h3>
            <button
              className="bg-[#89BF50] text-[16px] text-white w-[330px] md:w-[460px] rounded-[48px] py-1 px-20 md:px-0 mt-10 relative  "
              onClick={() => navigate("/nyheter/Nyheter")}
            >
              Så här jobbar vi med energioptimering{" "}
              <img
                src={after}
                alt="after"
                width={18}
                height={18}
                className="absolute bottom-2 right-14 md:right-5"
              />
            </button>
          </div>
        </div>
      </div>
      <div
        className={` py-8 px-5  md:mt-10 lg:mt-20 xl:mt-8 2xl:mt-16  flex flex-col-reverse md:flex-row gap-5 lg:gap-0 md:justify-around w-full items-center  absolute top-0 left-0 duration-700  ${
          slide === 1 ? "opacity-100" : "opacity-0"
        } `}
      >
        <div className="flex flex-col items-center md:items-start">
          <div className=" text-center md:text-start max-w-[450px] lg:max-w-[560px] 2xl:max-w-[760px] text-[20px] md:text-[18px] lg:text-[20px] 2xl:text-[32px] ">
            Framtidens energilagring för företag, Bostadsrättsföreningar och
            fastighetsägare.
          </div>
          <p className="  text-center md:text-start  max-w-[380px] lg:max-w-[589px] 2xl:max-w-[689px] text-[16px] md:text-[14px] lg:text-[16px] 2xl:text-[20px] mt-3 2xl:mt-5 ">
            Med EM EL & DATAs batterilagringslösningar kan ni inte bara optimera
            energianvändningen och sänka kostnader, utan också skapa nya
            intäktsmöjligheter genom att delta på frekvensmarknaden. Skapa nya
            intäktsmöjligheter och få lägre elpriser samtidigt som ni bidrar
            till en hållbar framtid. Återbetalningstiden med nuvarande
            snittavskastning är 2 år med garantitider på upp till 10 år på
            batterierna. Ta kontroll över energin, sänk driftkostnaderna och
            stärk ekonomin med smart batterilagring idag. Få en kostnadsfri
            offert redan idag.
          </p>

          <button
            className=" text-[10px] md:text-[12px] lg:text-[16px] uppercase font-bold bg-[#00354E] rounded-[14px] text-white px-12 py-4 ml-0 md:ml-32 lg:ml-44 mt-3 2xl:mt-5"
            onClick={() => navigate("/kontakt")}
          >
            Kontakta oss
          </button>
        </div>
        <div className="flex flex-col md:flex-row items-center justify-center">
          <img
            src={image}
            width={300}
            height={380}
            alt="image"
            className="hidden md:block w-[160px] h-[230px] lg:w-[200px] lg:h-[280px] 2xl:w-[300px] 2xl:h-[380px] cursor-pointer"
            onClick={() => slide === 1 && navigate("/nyheter/batterilagring")}
          />
          <img
            src={batteri}
            width={342}
            height={428}
            alt="image"
            className="w-[200px] h-[230px] lg:w-[242px] lg:h-[328px] 2xl:w-[342px] 2xl:h-[428px]"
          />
        </div>
      </div>
    </div>
  );
};

export default EnergieffektivaSection;
