import React from "react";

import FooterSection from "../components/FooterSection";
import HeroSection from "../components/halbarhet/HeroSection";
import Egestam from "../components/halbarhet/Egestam";
import { DropDownSection } from "../components/halbarhet/DropDownSection";
import Optimerade from "../components/halbarhet/Optimerade";
import Välkommen from "../components/home/VälkommenSection";
import Bravida from "../components/Bravida";
import Hallbar from "../components/halbarhet/Hallbar";
import Vara from "../components/halbarhet/Vara";
import ScrollToTop from "../components/scrollToTop";
import OptimeradeMd from "../components/halbarhet/OptimeradeMd";

const Hallbarhet = () => {
  return (
    <div className="flex w-full flex-col justify-center items-center">
      <ScrollToTop />
      <HeroSection />
      <Egestam />
      <Vara />
      <DropDownSection />
      <Optimerade />
      <Hallbar />

      <div className="md:hidden">
        <Välkommen />
      </div>
      <Bravida />
      <OptimeradeMd />
      <FooterSection />
    </div>
  );
};

export default Hallbarhet;
