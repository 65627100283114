import React, { useEffect, useState } from "react";
import FooterSection from "../components/FooterSection";
import Välkommen from "../components/home/VälkommenSection";
import Bravida from "../components/Bravida";
import ProjektSection from "../components/home/ProjektSection";
import News from "../components/article/News";
import Content from "../components/article/Content";
import HeroSection from "../components/article/HeroSection";
import { api } from "../api/axios";
import { useLocation, useParams } from "react-router-dom";
import ScrollToTop from "../components/scrollToTop";

const Article = () => {
  const { id } = useParams();
  const [allData, setAllData] = useState([]);
  const [data, setData] = useState();
  const [assets, setAssets] = useState([]);
  const location = useLocation();
  const primeryTags = ["Projekt", "Tjänster", "Nyheter"];

  useEffect(() => {
    if (location.state) {
      api
        .get(
          "/entries?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE&content_type=projectPost"
        )
        .then(({ data }) => {
          setAllData(data);
          setAssets(data?.includes?.Asset);
          setData(
            data?.items?.find(
              (content) => content?.fields?.homeSlug.trim() === id
            )
          );
        })
        .catch((error) => alert(error.message));
    } else {
      api
        .get(
          "/entries?access_token=2BMWcZki8_EUyB_VwhdGv0N2rmx5YbIUaKoipK-tFaE&content_type=newsPosts2"
        )
        .then(({ data }) => {
          setAllData(data);
          setAssets(data?.includes?.Asset);
          setData(
            data?.items?.find(
              (content) => content?.fields?.homeSlug.trim() === id
            )
          );
        })
        .catch((error) => alert(error.message));
    }
  }, [id, location.state]);

  const findImage = (id) => {
    const url = assets.find((asset) => asset.sys.id === id);
    if (url) return "https:" + url?.fields?.file?.url;
  };

  const secondryTags = data?.metadata?.tags.filter(
    ({ sys }) => !primeryTags.includes(sys.id)
  );

  const related = allData.items?.filter(
    (data) =>
      data?.metadata?.tags?.some(({ sys }) =>
        secondryTags?.some((secondryTag) => secondryTag.sys.id === sys.id)
      ) && data.fields.homeSlug !== id
  );

  return (
    <>
      <ScrollToTop />
      <HeroSection
        bannerDesktope={findImage(data?.fields?.articleBannerImage?.sys?.id)}
        bannerMobile={findImage(
          data?.fields?.articleBannerImageMobileView?.sys?.id
        )}
        newsHeading={
          data?.fields?.articleHeading?.content[0]?.content[0]?.value
        }
        homeSlug={data?.fields?.articleSlug}
      />
      <Content content={data?.fields} />
      <News
        allData={allData}
        related={related}
        homeSlug={data?.fields?.articleSlug}
      />
      <div className="2xl:hidden lg:px-8">
        <ProjektSection />
      </div>
      <div className="md:hidden">
        <Välkommen />
      </div>
      <Bravida />
      <FooterSection />
    </>
  );
};

export default Article;
