import React from "react";
import Hero from "../components/kontakt/Hero";
import Section from "../components/kontakt/Section";
import FooterSection from "../components/FooterSection";
import Address from "../components/kontakt/Address";
import ScrollToTop from "../components/scrollToTop";

const Kontakt = () => {
  return (
    <>
      <ScrollToTop />
      <Hero />
      <Address />
      <Section />
      <FooterSection />
    </>
  );
};

export default Kontakt;
